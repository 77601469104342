<template>
  <div class="sectors">
    <EmptyProvider :text="`<span>No sectors created yet.<br>Click 'Add' to set up your first one!</span>`">
      <a-table
        :dataSource="dataSource"
        :pagination="false"
        :row-key="(record) => record.id"
        :columns="columns"
        :components="components"
      >
        <div slot="customTitle" class="d-flex align-items-center">
          <span class="d-flex mr-2">Chance</span>
          <a-popconfirm
            placement="left"
            title="Are you sure you want to set equal chances for all sectors?"
            @confirm="() => equalizeSectorsChances()"
          >
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>Equalize Chances</span>
              </template>
              <ScalesIcon v-if="!isActiveWheel && editPermission && dataSource.length" style="max-width: 16px;" />
            </a-tooltip>
          </a-popconfirm>
        </div>
        <template slot="drag" slot-scope="text, record, index">
          <div class="d-flex align-items-center justify-content-center">
            <span v-if="isActiveWheel || !editPermission || dataSource.length <= 1">{{ index + 1 }}</span>
            <a-icon v-else type="menu" class="sectors__sector__icon handle" />
          </div>
        </template>
        <template slot="image" slot-scope="image, record">
          <img v-if="record.image" :src="record.image" class="sectors__sector__image" :alt="record.name" />
          <img v-else src="/resources/images/default-image.svg" class="sectors__sector__image" alt="Default" />
        </template>
        <template slot="type" slot-scope="type">
          {{ getTypeName(type) }}
        </template>
        <template slot="name" slot-scope="name">
          {{ name ? name : '—' }}
        </template>
        <template slot="description" slot-scope="description">
          {{ description ? description : '—' }}
        </template>
        <template slot="actions" slot-scope="text, record, index">
          <div class="sectors__sector__actions">
            <a-icon class="font-size-18 text-orange" type="eye" @click="$router.push(`/fortune-wheel/${currentWheelId}/view-sector/${record.id}`)" />
            <a-tooltip placement="topLeft">
              <template v-if="isActiveWheel" slot="title">
                <span>Stop the Wheel to edit</span>
              </template>
              <a-icon
                v-if="editPermission"
                class="font-size-18"
                :class="isActiveWheel ? 'text-default cursor-default' : 'text-primary'"
                type="edit"
                @click="isActiveWheel ? () => {} : $router.push(`/fortune-wheel/${currentWheelId}/edit-sector/${record.id}`)"
              />
            </a-tooltip>
            <a-tooltip placement="topLeft">
              <template v-if="isActiveWheel" slot="title">
                <span>Stop the Wheel to edit</span>
              </template>
              <a-popconfirm
                placement="left"
                title="Are you sure you want to delete this sector?"
                :disabled="isActiveWheel"
                @confirm="() => deleteSector(record, index)"
              >
                <a-icon
                  v-if="deletePermission"
                  class="font-size-18"
                  :class="isActiveWheel ? 'text-default' : 'text-danger delete-icon'"
                  type="delete"
                />
              </a-popconfirm>
            </a-tooltip>
          </div>
        </template>
      </a-table>
    </EmptyProvider>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'
import EmptyProvider from '@/components/main/empty/emptyProvider'
import DraggableWrapper from '@/components/custom/draggableWrapper'
import ScalesIcon from '@/components/custom/icons/scalesIcon.vue'
import { deleteSector } from '@/views/apps/fortune-wheel/composables/useDeleteSector.js'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

export default {
  props: {
    currentWheelId: {
      type: Number,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    isActiveWheel: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      data: this,
    }
  },
  components: {
    EmptyProvider,
    ScalesIcon,
  },
  data() {
    return {
      columns: [
        {
          title: '',
          dataIndex: 'drag',
          scopedSlots: { customRender: 'drag' },
          width: '5%',
        },
        {
          title: 'Sector image',
          dataIndex: 'image',
          scopedSlots: { customRender: 'image' },
          width: '15%',
        },
        {
          title: 'Sector type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: '15%',
        },
        {
          title: 'Sector name',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: '20%',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
        },
        {
          dataIndex: 'chance',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'chance' },
          width: '110px',
        },
        {
          title: '',
          dataIndex: 'actions',
          align: 'right',
          width: '130px',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      components: {
        body: {
          wrapper: DraggableWrapper,
        },
      },
      currentSnapshotSectorPositions: [],
      sectorsSnapshot: [],
    }
  },
  watch: {
    currentSnapshotSectorPositions: {
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.sectorsSnapshot)) {
          this.$emit('toggleSave', true)
        } else {
          this.$emit('toggleSave', false)
        }
      },
      deep: true,
    },
  },
  methods: {
    getTypeName(type) {
      switch (type) {
        case 'generic':
          return 'Generic'
        case 'free_bet':
          return 'Freebet'
        case 'promo_code':
          return 'Promo code'
        case 're_spin':
          return 'Re-spin'
        default:
          return '—'
      }
    },
    setCurrentSnapshotSectorPositions(data = []) {
      this.currentSnapshotSectorPositions = data.map((sector, index) => {
        return { id: sector.id, position: index }
      })
    },
    setSectorsSnapshot(data = []) {
      this.sectorsSnapshot = data.map((sector, index) => {
        return { id: sector.id, position: index }
      })
    },
    async sortSectors() {
      const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}/sectors/sort`
      const data = this.dataSource.map((sector, index) => {
        return { id: sector.id, position: index }
      })
      try {
        this.loading = true
        const response = await apiClient.post(url, data)
        if (response?.data?.success) {
          this.$notification.success({
            message: response?.data?.message ? response.data.message : 'The wheel sectors have been successfully sorted and updated',
          })
          this.setSectorsSnapshot(this.dataSource)
          this.$emit('toggleSave', false)
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async equalizeSectorsChances() {
      try {
        this.loading = true
        const url = `${wheelApiUrl}/admin/wheels/${this.currentWheelId}/sectors/equalize`
        const response = await apiClient.post(url)
        if (response?.data?.success) {
          this.$emit('equalizeSectors', response.data.data)
          this.$notification.success({
            message: response?.data?.message ? response.data.message : 'Successfully equalized chances for all sectors of the wheel',
          })
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async deleteSector(sector, index) {
      try {
        this.loading = true

        const response = await deleteSector(this.currentWheelId, sector.id)
        if (response?.data?.success) {
          this.$emit('removeSector', index)
          this.$notification.success({
            message: `Sector "${sector.name}" removed`,
          })
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.setSectorsSnapshot(this.dataSource)
    this.setCurrentSnapshotSectorPositions(this.dataSource)
    this.$root.$on('endDraggable', () => {
      this.setCurrentSnapshotSectorPositions(this.dataSource)
    })
  },
}
</script>

<style lang="scss">
.sectors {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__sector {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #e4e9f0;
    border-radius: 4px;
    padding: 5px 20px;
    gap: 20px;
    &__icon {
      &:hover {
        color: var(--kit-color-primary);
        cursor: pointer;
      }
    }
    &__image {
      height: 70px;
      border-radius: 2px;
    }
    &__name {
      flex: 1 1 auto;
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}
</style>
