<template>
  <div class="fortune-wheel-form">
    <HeadSection
      title=""
      :breadcrumbs="breadcrumbs"
      :permissionOfSection="permissionOfSection"
    />
    <MainContainer :loading="userLoading || loadingWheel" :permissionOfSection="permissionOfSection">
      <div class="d-flex justify-content-between align-items-center">
        <router-link :to="`/fortune-wheel/${currentWheelId}/view`">
          <h1 class="text-primary">{{ currentWheelName }}</h1>
        </router-link>
        <div class="d-flex" v-if="editPermission">
          <a-button
            :type="isActiveWheel ? 'danger' : 'primary'"
            size="large"
            class="mr-2"
            :disabled="loading"
            :loading="loading"
            @click="toggleStatusWheelModal(true)"
          >
            <span class="px-2">{{ isActiveWheel ? 'Stop' : 'Start' }}</span>
          </a-button>
          <a-tooltip placement="topLeft">
            <template v-if="isActiveWheel" slot="title">
              <span>Stop the Wheel to edit</span>
            </template>
            <a-button
              :disabled="loading || isActiveWheel"
              size="large"
              @click="$router.push(`/fortune-wheel/${currentWheel.id}/edit`)"
            >
              <span class="px-2">Edit</span>
            </a-button>
          </a-tooltip>
        </div>
      </div>
      <div v-if="currentWheel" class="d-flex flex-wrap mt-3" style="gap: 140px;">
        <div class="d-flex flex-column" style="gap: 10px; max-width: 310px;">
          <span class="font-size-20 font-weight-bold">Activity info</span>
          <div class="d-flex flex-column">
            <span class="d-block mb-2 font-weight-bold font-size-16">Status</span>
            <span
              :class="currentWheel.status === 'active' ? 'text-primary' : 'text-danger'"
              class="text-capitalize"
            >
              {{ currentWheel.status }}
            </span>
          </div>
          <div class="d-flex flex-column">
            <span class="d-block mb-2 font-weight-bold font-size-16">Activity Period</span>
            <div v-if="currentWheel.starts_at || currentWheel.ends_at" class="d-flex align-items-center" style="gap: 5px;">
              <span :class="{ 'text-default' : !currentWheel.starts_at }">{{ currentWheel.starts_at ? currentWheel.starts_at : 'Not specified' }}</span>
              —
              <span :class="{ 'text-default' : !currentWheel.ends_at }">{{ currentWheel.ends_at ? currentWheel.ends_at : 'Not specified' }}</span>
            </div>
            <span class="text-default" v-else>Not specified</span>
          </div>
        </div>
        <div class="d-flex flex-column" style="gap: 10px; max-width: 310px;">
          <span class="font-size-20 font-weight-bold">Apps & Segment</span>
          <div class="d-flex flex-column">
            <span class="d-block mb-2 font-weight-bold font-size-16">Apps</span>
            <div class="d-flex">
              <a-tag
                v-for="app in currentWheel.applications.slice(0, 2)"
                :key="app.application_id"
                style="
                  margin-right: 5px;
                  padding: 1px 8px;
                  border-radius: 2px;
                "
                class="cursor-pointer"
                @click="toggleAppModal(true)"
              >
                {{ app.name }}
              </a-tag>
              <a-tag
                v-if="currentWheel.applications.length > 2"
                @click="toggleAppModal(true)"
              >
                {{ `+${currentWheel.applications.length - 2}` }}
              </a-tag>
            </div>
          </div>
          <div class="d-flex flex-column">
            <span class="d-block mb-2 font-weight-bold font-size-16">Segment</span>
            {{ currentWheel.is_auth_only ? currentWheel.segment.name : 'Unauthorized users' }}
          </div>
        </div>
        <div class="d-flex flex-column" style="gap: 10px; max-width: 310px;">
          <span class="font-size-20 font-weight-bold">Details</span>
          <div class="d-flex flex-column">
            <span class="d-block mb-2 font-weight-bold font-size-16">Languages</span>
            <div class="d-flex">
              <a-tag
                v-for="lang in currentWheel.languages.slice(0, 3)"
                :key="lang.id"
                style="
                  margin-right: 5px;
                  padding: 1px 8px;
                  border-radius: 2px;
                "
                class="cursor-pointer"
                @click="toggleLanguageModal(lang, true)"
              >
                {{ lang.name }}
                <a-badge
                  v-if="lang.is_default"
                  count="Default"
                  :number-style="{
                    backgroundColor: '#fff',
                    color: '#999',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }"
                  style="transform: scale(0.8);"
                />
              </a-tag>
              <a-tag
                v-if="currentWheel.languages.length > 3"
                class="mr-0"
                @click="toggleLanguageModal(null, true)"
              >
                {{ `+${currentWheel.languages.length - 3}` }}
              </a-tag>
            </div>
          </div>
          <div class="d-flex flex-column">
            <span class="d-block mb-2 font-weight-bold font-size-16">Display Frequency (days)</span>
            {{ currentWheel.show_frequency }}
          </div>
        </div>
      </div>
      <a-row class="mt-5">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <span class="font-weight-bold font-size-16">Sectors</span>
            <a-tooltip placement="topLeft" arrow-point-at-center>
              <template slot="title">
                <span>There must be an even number of sectors, from 6 to 12. The sum of chances in the sectors must be 100</span>
              </template>
              <a-icon class="d-flex ml-2" type="info-circle" />
            </a-tooltip>
          </div>
          <a-tooltip placement="topLeft">
            <template v-if="isActiveWheel || maxNumberOfSectors" slot="title">
              <span v-if="isActiveWheel">Stop the Wheel to edit</span>
              <span v-if="maxNumberOfSectors && !isActiveWheel">The maximum allowed number of sectors is 12</span>
            </template>
            <a-button
              v-if="editPermission && !isActiveWheel && showSaveSortBtn"
              type="primary"
              size="large"
              :loading="sortLoading"
              class="mr-2 text-white success-color"
              @click="sortSectors"
            >
              Save sectors order
            </a-button>
            <a-button
              v-if="createPermission"
              type="primary"
              size="large"
              :disabled="isActiveWheel || maxNumberOfSectors"
              @click="$router.push(`/fortune-wheel/${currentWheel.id}/create-sector`)"
            >
              Add
            </a-button>
          </a-tooltip>
        </div>
        <Sectors
          v-if="currentWheel"
          ref="sectors-table"
          class="mt-3"
          :currentWheelId="currentWheel.id"
          :dataSource="currentWheel?.sectors ? currentWheel.sectors : []"
          :isActiveWheel="isActiveWheel"
          :editPermission="editPermission"
          :deletePermission="deletePermission"
          @toggleSave="toggleShowSaveSortBtn"
          @equalizeSectors="equalizeSectors"
          @removeSector="removeSector"
        />
      </a-row>
    </MainContainer>
    <a-modal
      v-model="appsModal"
      title="Selected apps"
      :footer="null"
      width="700px"
      :destroyOnClose="true"
    >
      <AppsModal
        :modalAppsData="[]"
        :availableApplications="selectedApplications"
        :readonly="true"
        @close="toggleAppModal(false)"
      />
    </a-modal>
    <a-modal
      v-model="languageModal"
      title="Selected Languages"
      :footer="null"
      width="500px"
      :destroyOnClose="true"
      @cancel="toggleLanguageModal(false)"
    >
      <LanguageModal
        :selectedLanguage="selectedLanguage"
        :selectedLanguages="currentWheel?.languages ? currentWheel.languages : []"
        :languageOptions="availableLanguages"
        :readonly="true"
        @close="toggleLanguageModal(false)"
      />
    </a-modal>
    <a-modal
      v-model="changeStatusWheelModal"
      :title="titleStatusWheelModal"
      width="400px"
      centered
      :destroyOnClose="true"
    >
      <p style="margin-bottom: 0;">{{ textStatusWheelModal }}</p>
      <template #footer>
        <a-button
          :disabled="loading"
          @click="toggleStatusWheelModal(false)"
        >
          Cancel
        </a-button>
        <a-button
          :type="actionButtonStatusWheelModal.type"
          :loading="loading"
          @click="changeStatusWheel"
        >
          {{ actionButtonStatusWheelModal.text }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import HeadSection from '@/components/main/headSection'
import MainContainer from '@/components/main/mainContainer'
import Sectors from '@/views/apps/fortune-wheel/components/Sectors.vue'
import AppsModal from '@/components/custom/modals/appsModal.vue'
import LanguageModal from '@/components/custom/modals/languageModal.vue'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'
import { mapState } from 'vuex'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

export default {
  props: {
    createPermission: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeadSection,
    MainContainer,
    Sectors,
    AppsModal,
    LanguageModal,
  },
  data: () => ({
    permissionOfSection: 'fortune_wheel',
    currentWheel: null,
    breadcrumbs: [{ name: 'Fortune wheel', link: '/fortune-wheel' }],
    selectedLanguage: null,
    showSaveSortBtn: false,
    sortLoading: false,
    languageModal: false,
    appsModal: false,
    changeStatusWheelModal: false,
    loadingWheel: false,
    loading: false,
  }),
  computed: {
    ...mapState(['user']),
    userLoading() {
      return this.$store.state?.user?.loading || false
    },
    currentWheelId() {
      return this.currentWheel ? this.currentWheel.id : ''
    },
    currentWheelName() {
      return this.currentWheel ? this.currentWheel.name : ''
    },
    isActiveWheel() {
      return this.currentWheel?.status === 'active'
    },
    selectedApplications() {
      return this.currentWheel && this.currentWheel?.applications?.length ? this.currentWheel.applications : []
    },
    availableLanguages() {
      return this.currentWheel && this.currentWheel?.languages?.length
        ? this.currentWheel.languages.map(lang => {
          return { name: lang.name, value: lang.language_id }
        })
        : []
    },
    minNumberOfSectors() {
      return this.currentWheel?.sectors?.length > 5
    },
    maxNumberOfSectors() {
      return this.currentWheel?.sectors?.length === 12
    },
    sumOfSectorChances() {
      if (!this.currentWheel?.sectors?.length) {
        return false
      }
      const result = this.currentWheel?.sectors.reduce((sum, sector) => {
        return sum + sector.chance
      }, 0)
      return result === 100
    },
    titleStatusWheelModal() {
      return this.isActiveWheel ? 'Stop the Wheel?' : 'Start the Wheel?'
    },
    textStatusWheelModal() {
      return this.isActiveWheel
        ? 'Stopping the wheel will reset its issuance history, making it available to all users again. Are you sure you want to proceed?'
        : 'Once activated, editing will no longer be possible. To make changes, you must stop the wheel, which will reset its issuance history and make it available to all users again.'
    },
    actionButtonStatusWheelModal() {
      return this.isActiveWheel ? { text: 'Stop', type: 'danger' } : { text: 'Start', type: 'primary' }
    },
  },
  methods: {
    async changeStatusWheel() {
      try {
        this.loading = true

        const url = `${wheelApiUrl}/admin/wheels/${this.currentWheel.id}/status`
        const data = {
          status: this.isActiveWheel ? 'inactive' : 'active',
        }
        const response = await apiClient.post(url, data)
        if (response?.data?.success) {
          this.toggleStatusWheelModal(false)
          this.currentWheel.status = this.isActiveWheel ? 'inactive' : 'active'
          this.$notification.success({
            message: 'Status',
            description: response?.data?.message || '',
          })
        } else {
          getErrorMessage(response?.data)
          this.toggleStatusWheelModal(false)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    toggleShowSaveSortBtn(status) {
      this.showSaveSortBtn = status
    },
    async sortSectors() {
      if (this.$refs['sectors-table']) {
        this.sortLoading = true
        await this.$refs['sectors-table'].sortSectors()
        this.sortLoading = false
      }
    },
    toggleAppModal(status) {
      if (this.loading) {
        return
      }
      this.appsModal = status
    },
    toggleLanguageModal(lang = null, status) {
      if (this.loading) {
        return
      }
      this.selectedLanguage = lang
      this.languageModal = status
      if (!status) {
        this.selectedLanguage = null
      }
    },
    toggleStatusWheelModal(status) {
      this.changeStatusWheelModal = status
    },
    equalizeSectors(sectors) {
      this.currentWheel.sectors = sectors
    },
    removeSector(index) {
      this.currentWheel.sectors.splice(index, 1)
    },
    async getCurrentWheel() {
      try {
        this.loadingWheel = true
        const currentWheelId = this.$route.params.id
        const url = `${wheelApiUrl}/admin/wheels/${currentWheelId}`
        const response = await apiClient.get(url)
        if (response?.data?.success) {
          this.currentWheel = response.data.data
          this.breadcrumbs.push({ name: this.currentWheel.name, link: undefined })
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loadingWheel = false
      }
    },
  },
  async created() {
    this.getCurrentWheel()
  },
}
</script>
